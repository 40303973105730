.statWrapper {
   color: var(--purple);
   width: 80%;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: calc(100vh - 110px);
   position: relative;
}

.statHeader {
   align-self: flex-start;
   font-weight: bolder;
   font-size: 32px;
   padding-top: 10px;
}

.accuracy {
   color: var(--violet)
}

.confMat {
   padding: 20px 0 20px 0;
   width: 450px;
}

.mechanismContainer {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   width: 100%;
   grid-auto-flow: column;
}

@media(max-width: 1100px) {
   .statWrapper {
      width: 90%;
   }
}
@media(min-width:901px) and (max-width:1400px) {
   .mechHolder {
      transform: scale(0.88)
   }
}

@media(min-width:701px) and (max-width:900px) {
   .mechHolder {
      transform: scale(0.75)
   }
}

@media(max-width:700px) {
   .mechanismContainer {
      display: flex;
      flex-direction: column;
   }
   .confMat {
      width: 375px
   }
}