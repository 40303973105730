.footer {
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: fixed;
   bottom: 0;
   border-top: 2px solid var(--purple);
   padding: 8px 30px;
   text-align: end;
   color: var(--purple);
   width: 100%;
   background-color: white;
}

#acknowledgement {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

#nsf {
   height: 30px;
   padding-right: 8px
}

#courtesy2 {
   display: None
}

@media(max-width:1000px) {
   #tystatement{
      display: None
   }

   #courtesy {
      display: None
   }

   #courtesy2 {
      display: block;
   }
}