.bigWrap {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.uploadWrapper {
   margin-top: 10px;
   margin-left: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
}

.browseWrapper {
   display: flex;
   align-items: center;
}

.fileMenu {
   width: 100%;
}

.fileFinder {
   font-family: inherit;
   font-size: 32px;
   cursor: pointer;
}

.hiddenInput {
   display: none;
}

.browseButton {
   text-align: center;
   background: var(--purple);
   border-radius: 5px;
   color: white;
   cursor: pointer;
   padding: 4px 0;
   font-size: 20px;
   width: 181px;
}

.browseButton:hover {
   background-color: var(--violet);
}

.fileList {
   list-style: none;
   border: 2px solid var(--purple);
   border-radius: 5px;
   margin: 10px 0;
   padding: 2px 16px;
   overflow: auto;
}

.fileItem {
   border-bottom: 1px solid lightgrey;
   width: 145px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   padding-left: 3px;
}

.highlighted {
   border-bottom: 1px solid lightgrey;
   width: 145px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   color: white;
   background-color: rgba(113, 61, 173, 0.5);
   padding-left: 3px;
   border-radius: 3px;
}

.fileItem:last-child {
   border-bottom: none;
}

.submit {
   background-color: var(--purple);
   color: white;
   border-radius: 5px;
   text-align: center;
   font-size: 20px;
   cursor: pointer;
   padding: 4px 16px;
}

.submit:hover {
   background-color: var(--violet);
}

@media(max-width: 700px) {
   .uploadWrapper {
      width: 80%;
   }
}