:root {
   --purple: #27054d;
   --violet: #713dad;
}

.navWrapper {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 8px 30px;
   border-bottom: 2px solid var(--purple);
}

.classifyImg {
   height: 50px
}

.navLinks {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.link {
   list-style: none;
   margin-left: 15px;
   cursor: pointer;
   color: var(--purple);
   font-size: 32px;
}

.link:hover {
   color: var(--violet);
}

.navLink {
   text-decoration: none;
}

.hammenu {
   display: none;
   cursor: pointer;
}

.icon:hover {
   transform: scale(1.1);
}

@media(max-width:1000px) {
   .classifyImg {
      height: 35px;
   }

   .link {
      font-size: 24px
   }
}

@media(max-width:600px) {

   .hammenu {
      display: block;
      size: 10px;
      position: relative;
   }

   .bignav {
      display: none
   }

   .smallNav {
      position: absolute;
      top: 42px;
      right: -30px;
      padding: 10px 25px;
      background-color: var(--purple);
      z-index: 10;
      border-radius: 0 0 0 15px;
   }

   .smallNav > .navLinks {
      flex-direction: column;
      align-items: flex-start;
   }

   .smallNav > .navLinks > .navLink > .link {
      color: white;
      margin-left: 0px;
   }

   .smallNav > .navLinks > .navLink > .link:hover {
      color: lightgray;
   }
}