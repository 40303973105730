.welcomeWrapper {
   padding-top: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 50%;
   margin: 0px auto;
   color: var(--purple)
}

.welcome {
   font-size: 42px;
}

/* ul {
   list-style-type: upper-roman;
   display: flex;
   flex-direction: column;
   align-items: center;
} */

.welcomeStatement {
   padding: 5px 0;
   margin-bottom: 5px;
   font-size: 20px;
   text-align: justify;
}

.sampleDoc {
   color: var(--violet);
   text-decoration: none;
   font-weight: bolder;
}

.dataReqs {
   padding: 10px 0;
   border-top: 2px lightgray solid;
   list-style-type: decimal;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.formatEx {
   width: 350px;
   padding-bottom: 10px;
}

.welcomeButton {
   padding: 6px 10px;
   background-color: var(--purple);
   color: white;
   border-radius: 5px;
   font-size: 20px;
}

.welcomeButton:hover {
   background-color: var(--violet);
   cursor: pointer;
}

@media(max-width: 1000px) {
   .welcomeWrapper {
      width: 80%;
   }
   .welcome {
      font-size: 36px;
   }
   .welcomeStatement {
      font-size: 18px;
   }
   .formatEx {
      width: 300px
   }
   .dataReqs {
      padding-left: 15px
   }
}