:root {
   --imw: 125px
}

.imgWrapper {
   position: relative;
   height: var(--imw);
   width: var(--imw);
   overflow: hidden;
   border: 2px solid var(--purple);
   border-radius: 5px;
}

.proPic {
   width: var(--imw);
   height: var(--imw);
   object-fit: cover;
}

.photoName {
   position: absolute;
   bottom: 0px;
   padding: 2px 0 2px 0;
   left: 0px;
   color: white;
   background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
   width: 121px;
   border-radius: 0 0 3px 3px;
   text-align: center;
}

.imgWrapper:hover > .proPic {
   transform: scale(1.02);
}

.imgWrapper:hover > .photoName {
   color: var(--violet);
}

.photoCard {
   grid-area: 'card'
}

@media(max-width: 1400px) {
   .photoCard {
      transform: scale(80%);
   }
}

@media(max-width: 800px) {
   :root {
      --imw: 140px;
   }
}