.pickWrapper {
   margin: 0 auto;
   color: var(--purple);
   width: 80%;
}

.modelSelection {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   margin-top: 20px;
}

.modelName {
   border-bottom: 2px solid rgba(212, 208, 207, 1);
}

.modelOption {
   width: 48%;
   padding: 5px 10px 5px 10px;
   border: 2px solid var(--violet);
   border-radius: 5px;
   cursor: pointer;
}

.aboutModel {
   margin-top: 8px;
   text-align: justify;
}

@media(max-width: 1000px) {
   .modelSelection {
      flex-direction: column;
      align-items: center;
   }
   .modelOption {
      width: 100%;
      margin-bottom: 10px;
   }
}