.formWrapper {
   width: 60%;
   margin: 0 auto;
   color: var(--purple);
   height: calc(100vh - 110px);
   font-size: 20px;
   padding-top: 20px;
   text-align: justify;
}

.feedBackForm {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.formTable {
   padding-top: 10px;
   display: flex;
   align-items: center;
}

.tableBody {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.formRow {
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 100%;
   border-bottom: 1px solid rgba(212, 208, 207, 0.4);
}

.tableCell {
   text-align: center;
   font-size: 20px;
}

.generalComments {
   height: 100px;
   margin: 20px 0 20px 0;
   border-style: none;
   background-color: rgba(212, 208, 207, 0.2);
   border-radius: 5px;
   padding: 5px;
   overflow: auto;
   resize: none;
}

.generalComments:focus {
   outline: none !important;
   border: 2px solid var(--purple)
}

input {
   background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.fileNames {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-size: 18px;
}

@media(max-width: 1000px) and (min-width: 701px) {
   .formWrapper {
      width: 90%
   }
   .tableCell {
      font-size: 16px;
   }
}

@media(max-width: 700px) {
   .formWrapper {
      width: 90%
   }
   .tableCell {
      font-size: 12px;
   }
}