.mechContainer{
   min-width: 250px;
   max-width: 250px;
   margin: 10px auto;
   background-color: rgba(212, 208, 207, 0.2);
   border-radius: 5px;
 }

 .mechName {
   /*background-color: rgba(212, 208, 207, 0.8);*/
   background-color: var(--purple);
   color: white;
   width: 100%;
   text-align: center;
   font-size: 25px;
   font-weight: bolder;
   border-radius: 5px 5px 0 0;
}
 
 .mechContent {
   position: relative;
   width: 100%;
   overflow: hidden;
   padding: 0px 6px 0 6px;
 }
 
 .mechContent .content-overlay {
   background: rgba(0,0,0,0.85);
   position: absolute;
   border-radius: 0 0 5px 5px;
   height: 101%;
   width: 100%;
   top: 0%;
   left: 0%;
   opacity: 0;
   -webkit-transform: translate(0%, -100%);
   -moz-transform: translate(0%, -100%);
   transform: translate(0%, -100%);
   -webkit-transition: all 0.2s ease-in-out 0s;
   -moz-transition: all 0.2s ease-in-out 0s;
   transition: all 0.2s ease-in-out 0s;
 }
 
 .mechContent:hover .content-overlay{
   opacity: 1;
   top: 100%;
   left: 0%;
 }
 
 .content-image{
   width: 100%;
 }
 
 .content-details {
   position: absolute;
   text-align: center;
   padding-left: 1em;
   padding-right: 1em;
   width: 100%;
   opacity: 0;
   -webkit-transition: all 0.2s ease-in-out 0s;
   -moz-transition: all 0.2s ease-in-out 0s;
   transition: all 0.2s ease-in-out 0s;
 }
 
 .mechContent:hover .content-details{
   padding-top: 8px;
   top: 0%;
   left: 0%;
   opacity: 1;
 }
 
.mechImg {
  width: 40%;
}

 .content-details p{
   color: #fff;
 }