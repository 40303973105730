.pageWrapper {
   display: flex;
   justify-content: space-around;
}

.aligner {
   display: flex;
   justify-content: space-around;
   align-items: center;
   flex-direction: column;
   width: 90%;
}

.preUploadSection {
   display: flex;
   align-items: center;
   padding-top: 40px;
   padding-bottom: 20px;
}

.dummyPic {
   height: 400px;
}

.imagePanel {
   position: relative;
}

[class^="img-"] {
   height: 30px;
   width: 30px;
   z-index: 100;
   border-radius: 50%;
   cursor: pointer;
   color: var(--purple);
   background-color: white;
   bottom: 7%;
}

[class^="img-"]:hover {
   color: var(--violet);
}

.img-last {
   position: absolute;
   left: 5%;
}

.img-next {
   position: absolute;
   left: 15%;
}

.predTable {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

@media(max-width: 700px) {
   .preUploadSection {
      flex-direction: column;
   }

   .dummyPic {
      height: 300px;
   }
}