.resultsTable {
   margin-top: 10px;
   border-collapse: collapse;
   /* overflow: scroll;  */
}

.colName {
   background-color: var(--purple);
   color: white;
   padding: 5px 10px;
   border-right: 1px solid white;
}

.colName:first-child {
   border-radius: 5px 0 0 0;
}

.colName:last-child {
   border-radius: 0 5px 0 0;
   border-right: none;
}

.entry {
   width: 90px;
   text-overflow: ellipsis;
   overflow: auto;
   padding: 0 5px;
   text-align: center;
}

.entry:first-child {
   max-width: 150px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   text-align: left;
}

.dataRow {
   border-bottom: 1px solid lightgray;
}

.dataRow:first-child {
   border: none;
   border-radius: 5px;
}

@media(max-width: 700px) {
   .entry {
      font-size: 12px;
   }
   .entry:first-child {
      max-width: 60px;
   }
   .colName {
      font-size: 12px;
   }
}