.aboutWrapper {
   color: var(--purple);
   width: 80%;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: calc(100vh - 110px);
   position: relative;
}

.aboutStatement {
   font-size: 20px;
   text-align: justify;
}

.aboutFig {
   padding-top: 20px;
   width: 500px;
}

.photoCards {
   margin-top: 20px;
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 100%;
   padding: 15px 0 10px 0;
}

@media(max-width: 800px) {
   .photoCards {
      display: grid;
      grid-template: 'card card card'
                     'card card card'
   }

   .aboutFig {
      width: 380px;
   }
}