@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Red Hat Display', sans-serif;
}

.mainWrapper {
  min-height: calc(100vh - 120px);
  overflow: auto;
  margin-bottom: 55px;
}

@media(max-width:1000px) {
  .mainWrapper {
    min-height: calc(100vh - 101px)
  }
}